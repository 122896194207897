
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter, useRoute } from "vue-router";
import CurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import { QuillEditor } from "@vueup/vue-quill";

export default defineComponent({
  name: "calculators",
  components: {
    Field,
    CurrencyInputNoSymbol,
    QuillEditor,
  },
  setup() {
    const { n } = useI18n();

    return {
      n,
    };
  },
  data() {
    const taxSettings = [] as any;

    return {
      store: useStore(),
      isTaxSettingsReady: false,
      taxSettings,
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_TAX_SETTINGS).then(() => {
        this.taxSettings = this.store.getters.getTaxSettingsData
          ? this.store.getters.getTaxSettingsData
          : [];

        setTimeout(() => {
          this.isTaxSettingsReady = true;
        }, 1000);
      });
    },
    onSaveTaxSettings() {
      this.store.dispatch(Actions.STORE_TAX_SETTINGS, this.taxSettings).then(
        () => {
          this.taxSettings = this.store.getters.getTaxSettingsData;
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Tax settings saved successfully",
          });
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error,
          });
        }
      );
    },
  },
  async mounted() {
    this.init();
  },
});
